.select {
  background-color: var(--white) !important;
  height: 36px !important;
  font-size: 13px;
  line-height: 15px;
  padding-left: 40px;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--white) !important;
  height: 39px !important;
  border-radius: 6px !important;
  font-size: 13px;
  line-height: 15px;
  z-index: 1;
  border: 2px solid var(--light-grey) !important;
  margin-right: 1rem;

  span {
    margin-top: 3px;
    margin-left: 10px;
  }

  .dropdown_caption {
    z-index: 5;
    width: 80px;
  }

  .select_dropdown {
    border: none !important;
    margin: 0px !important;
    margin-left: -1px !important;
    padding: 0px !important;
    width: min-content !important;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
